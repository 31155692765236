.fingerprint-container {
    max-width: 800px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.fingerprint-row {
    margin-bottom: 1.5rem;
}

.fingerprint-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
}

.fingerprint-label {
    font-size: 1.1rem;
    color: white;
    font-weight: 500;
    opacity: 0.9;
}

.copy-button {
    background: rgba(85, 174, 189, 0.2);
    border: 1px solid rgba(85, 174, 189, 0.3);
    color: white;
    padding: 0.4rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85rem;
    transition: all 0.2s ease;
}

.copy-button:hover {
    background: rgba(85, 174, 189, 0.3);
    transform: translateY(-1px);
}

.progress-wrapper {
    position: relative;
}

.progress {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    overflow: hidden;
}

.progress-bar {
    transition: width 1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
}

.progress-text {
    font-family: 'Courier New', monospace;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    letter-spacing: 0.5px;
}

.bg-success {
    background: linear-gradient(135deg, #55aebd, #cfde41) !important;
}

.bg-info {
    background: linear-gradient(135deg, #cfde41, #55aebd) !important;
}

@media (max-width: 768px) {
    .fingerprint-container {
        padding: 1rem;
    }

    .progress-text {
        font-size: 0.9rem;
    }

    .copy-button {
        padding: 0.3rem 0.8rem;
        font-size: 0.8rem;
    }
} 