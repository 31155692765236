.download-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin: 2rem 0;
}

.store-button {
    display: inline-flex;
    align-items: center;
    min-width: 180px;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.store-button:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
}

.store-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.store-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
}

.small-text {
    font-size: 0.7rem;
    opacity: 0.8;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.big-text {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;
}

/* Platform-specific styles */
.google-play .store-text {
    font-family: 'Roboto', sans-serif;
}

.app-store .store-text {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Icon styles */
.store-button svg {
    width: 24px;
    height: 24px;
    color: white;
    opacity: 0.9;
}

.store-button:hover svg {
    opacity: 1;
}

.google-play svg {
    margin-top: -2px;
}

.app-store svg {
    width: 22px;
    height: 22px;
    margin-left: 2px;
}

@media (max-width: 768px) {
    .download-buttons {
        flex-direction: column;
        gap: 1rem;
    }
    
    .store-button {
        width: 100%;
        justify-content: center;
    }

    .store-text {
        align-items: center;
    }
} 