:root {
    --accent-color: #C5FF32;
    --background-color: #020B2D;
}

.app {
    min-height: 100vh;
    background-color: var(--background-color);
    position: relative;
    z-index: 1;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.bg-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('./assets/bg.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.3;
}

.bg-grid {
    position: absolute;
    inset: 0;
    background-image: url('./assets/grid.svg');
    opacity: 0.1;
    pointer-events: none;
    z-index: 0;
}

.bg-gradient {
    position: absolute;
    inset: 0;
    background: linear-gradient(to right, rgba(59, 130, 246, 0.1), rgba(168, 85, 247, 0.1));
    pointer-events: none;
    z-index: 0;
}

.wavy-bg-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
}

.wavy-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
}

.navbar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    z-index: 10;
    padding: 1rem 0;
}

.navbar-brand img {
    transition: transform 0.2s ease;
}

.navbar-brand:hover img {
    transform: scale(1.05);
}

.navbar-nav {
    gap: 1.5rem;
}

.nav-link {
    color: white !important;
    opacity: 0.8;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.nav-link:hover {
    color: var(--accent-color) !important;
    opacity: 1;
}

.main-content {
    position: relative;
    z-index: 1;
    padding: 2rem 1rem;
    flex: 1;
}

.footer {
    position: relative;
    z-index: 1;
    background: rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer a {
    text-decoration: none;
    transition: color 0.2s ease;
}

.footer a:hover {
    color: var(--accent-color) !important;
}

@media (max-width: 768px) {
    .navbar-nav {
        gap: 1rem;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 1rem 0;
    }

    .nav-link {
        font-size: 0.8rem;
        padding: 0.5rem;
    }

    .footer .row > div {
        text-align: center !important;
        margin: 0.5rem 0;
    }
} 