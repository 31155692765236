.video-container { 
    max-width: 800px; 
    margin: 0 auto 2rem; 
    position: relative; 
    z-index: 2;
}

.video-container iframe {
    border: 0;
    pointer-events: none;
} 