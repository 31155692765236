/* Main section styling */
.section-dark {
    background-color: transparent;
    color: #ffffff;
    padding: 2rem 0;
}

/* Container for subscription form */
.subscription-container {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    background-color: rgba(13, 18, 38, 0.5);
    border-radius: 12px;
    padding: 2rem;
    backdrop-filter: blur(10px);
}

/* Email input field styling */
.form-control {
    background-color: rgba(255, 255, 255, 0.05);
    border: none;
    color: #ffffff;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border-radius: 8px;
    width: calc(100% - 120px);
    margin-right: 8px;
}

/* Input field focus state */
.form-control:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.1);
}

/* Input placeholder text */
.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

/* Subscribe button styling */
.btn-primary {
    background: linear-gradient(90deg, #4CAF50, #8BC34A);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-weight: 500;
    min-width: 100px;
}

/* Button hover effect */
.btn-primary:hover {
    opacity: 0.9;
}

/* Notification dropdown styling */
.notification-dropdown {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-10px);
    width: calc(100% - 2rem);
    padding: 0.75rem;
    margin-top: 0.5rem;
    border-radius: 8px;
    text-align: center;
    opacity: 0;
    transition: all 0.2s ease;
    pointer-events: none;
    background: rgba(13, 18, 38, 0.5);
    backdrop-filter: blur(10px);
}

/* Notification visible state */
.notification-dropdown.show {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
    pointer-events: auto;
}

/* Success notification variant */
.notification-dropdown.alert-success {
    background: rgba(76, 175, 80, 0.1);
    color: #4CAF50;
}

/* Error notification variant */
.notification-dropdown.alert-danger {
    background: rgba(244, 67, 54, 0.1);
    color: #f44336;
}

/* Warning notification variant */
.notification-dropdown.alert-warning {
    background: rgba(255, 152, 0, 0.1);
    color: #ff9800;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
    /* Adjust container padding for smaller screens */
    .subscription-container {
        margin: 0 1rem;
        padding: 1.5rem;
    }

    /* Make input field full width on mobile */
    .form-control {
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;
    }
    
    /* Make button full width on mobile */
    .btn-primary {
        width: 100%;
    }
    
    /* Stack form elements vertically on mobile */
    form.d-flex {
        flex-direction: column;
    }
} 